import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import React, { FC } from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 5vh;
  max-width: 770px;
  margin: 0 auto;
  @media ${MEDIA_QUERIES.PHONE} {
    padding: 0 20px;
  }
`;

const PageWrapper: FC = ({ children }) => <Wrapper>{children}</Wrapper>;

export default PageWrapper;
